import React, { useState } from 'react'
import checke from "../../../../images/safety/check.svg"
import uncheck from "../../../../images/safety/uncheck.svg"

const Check_Para = () => {
    const [check, setCheck] = useState();
    const checkHandler = () => {
        setCheck(!check);
    }
    return (
        <div className="part_e_para">
            <div className="part_e_img">
                {check ? (<img onClick={checkHandler} src={checke} alt="check img" />)
                    : (<img onClick={checkHandler} src={uncheck} alt="check img" />)
                }
            </div>
            <p>I confirm that all work associated with this permit is complete, all personal
            named above have exited the space, all tools and equipment have been removed
            from the space and that all plant and the confined space have been reinstated
            correctly and safely.</p>
        </div>
    )
}
export default Check_Para;
