import React, { useState, useEffect } from 'react'
import { Link } from "gatsby"
import ".././Safety.css";
import { connect } from "react-redux"
import { truck_content } from "../../../../actions/yardActions/TruckAction";
//import { useHistory } from "react-router-dom";
import circle_color from "../../../../images/safety/circle_color.svg";
import circle_blank_color from "../../../../images/safety/circle_blank_color.svg"
import Header from '../../../../components/Yard/common/Header';
import Update from '../../../../components/Yard/update/Update';
import Check_Para from '../comp/Check_Para';
import Button from "../../../../components/Yard/Button/Button"
import InputField from '../../../../components/Yard/InputField/InputField';
import DateTime from '../../../../components/Yard/dateTime/DateTime';
import {Routes} from '../../../../utils/route';
import {Spin } from 'antd';
const Safety_Part_E = (props) => {
    const id=props.id;
    const [loading, setLoading] = useState (true);
    const [state, setState] = useState("");
    const [sigcmp, setSigcmp] = useState()
    useEffect(() => {
        const callback=(val)=>{
            if(val===true){
                setLoading(false);
                props.truck_content(id);
            }}
          Routes('yard',callback)
    }, []);
    useEffect(()=>{
        if (props.single_truck !== null) {
            setState(props.single_truck);
        }
    },[props.single_truck])
    if(!loading){
    return (
        <>
            <section className="safety">
                <Header headName="safety details" LinkName={`/yard/safety/safety_part_d/${id}`} />
                <div className="safety_container">
                    <Update
                        update="safety_update"
                        circleImg_1={circle_blank_color} circleImg_2={circle_blank_color}
                        circleImg_3={circle_blank_color} circleImg_4={circle_blank_color} circleImg_5={circle_color}
                        border_1="safety_part_e_border_1" border_2="safety_part_e_border_2"
                        border_3="safety_part_e_border_3" border_4="safety_part_e_border_4"
                    />
                    <div className="safety_part_a">
                        <p>confined space entry permit</p>
                        <h3>Part E - Permit Close</h3>
                    </div>
                    <Check_Para />
                    <div className="sign2_container">
                        <div className="sign2_head">
                            <h3>signature</h3>
                            <Button className="sign2_add" name="add" />
                        </div>
                        <InputField idName="sign2_input_id" className="sign2_input" value={sigcmp} change={setSigcmp} />
                    </div>
                    <DateTime label_1="date" label_2="time" />
                    <div className="part_e_button">
                        <Link to={`/yard/safety/safety_part_d/${id}`}><Button className="part_e_button_1" name="Save & Close" /></Link>
                        <Link to={`/yard/wash_complete/${id}`}><Button className="part_e_button_2" name="Confirm & Submit" /></Link>
                    </div>
                </div>
            </section>
        </>
    )
}else{
    return(<div className="spinner-main"><Spin/></div>)
  }
}
const mapStateToProps = state => ({
    truckData:state.truck.truckData,
    single_truck:state.truck.single_truck
  })
export default connect(mapStateToProps, {truck_content})(Safety_Part_E)